import actions from './actions';
import getters from './getters';
import mutations from './mutations';

const state = {
    user: {
        name: null,
        domain: null,
        subscription_status: null,
        subscription_name: null
    }
};

export default {
    namespaced: true,
    state,
    actions,
    getters,
    mutations
};
