import * as types from './mutation-types';

const SET_USER = (state, data) => {
    let User = data.user;
    let Subscription = data.subscription;
    state.user = {
        name: User.first_name,
        email: User.email,
        domain: User.domain,
        subscription_status: Subscription.status,
        subscription_name: Subscription.name
    };
    state.access_token = localStorage.getItem('access_token') || '';
    state.loggedIn = true
};
const LOGOUT = (state) => {
    localStorage.removeItem('access_token');
    localStorage.removeItem('refresh_token');
    state.user = {
        name: null,
        domain: null,
        email: null,
        subscription_status: null,
        subscription_name: null
    };
    state.access_token = null;
    state.loggedIn = false

};
export default {
    [types.SET_USER]: SET_USER,
    [types.LOGOUT]: LOGOUT,
};
