import * as types from './mutation-types';

const CHANGE_TABLE_CHECKED_ITEMS = (state, data) => {
    if (data.checked) {
        if (state.tableCheckedItems[data.type].findIndex(item => item.id === data.itemId) === -1) {
            state.tableCheckedItems[data.type].push({id: data.itemId});
        }
    } else {
        state.tableCheckedItems[data.type] = state.tableCheckedItems[data.type].filter(item => item.id !== data.itemId);
    }
};


const UNSELECT_ENTRY_IDS_FROM_TABLE_CHECKED_ITEMS = (state, type) => {
    state.tableCheckedItems[type] = [];
};


export default {
    [types.CHANGE_TABLE_CHECKED_ITEMS]: CHANGE_TABLE_CHECKED_ITEMS,
    [types.UNSELECT_ENTRY_IDS_FROM_TABLE_CHECKED_ITEMS]: UNSELECT_ENTRY_IDS_FROM_TABLE_CHECKED_ITEMS,
};
