export default {
    getCookie(grCookie) {
        if (!grCookie) {
            return null;
        }
        //eslint-disable-next-line
        return decodeURIComponent(document.cookie.replace(new RegExp("(?:(?:^|.*;)\\s*" + encodeURIComponent(grCookie).replace(/[\-\.\+\*]/g, "\\$&") + "\\s*\\=\\s*([^;]*).*$)|^.*$"), "$1")) || '';
    },
    hash(s) {
        s = s.toString();
        let h = 0, l = s.length, i = 0;
        if ( l > 0 )
            while (i < l)
                h = (h << 5) - h + s.charCodeAt(i++) | 0;
        return h.toLocaleString('en-US', {
            minimumIntegerDigits: 6,
            useGrouping: false
        });

    }
}
