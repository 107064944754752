import { SETUP_FORM } from "../../../../../shared/links";
import axios from "axios";
import * as types from "./mutation-types";


export default {
    getSettings(_, type) {
        let url = SETUP_FORM.SETTINGS + type;
        return axios.get(url).then((response) => {
            return response.data;
        });
    },

    setSettings(_, data) {
        let url = SETUP_FORM.SETTINGS + data.type;
        return axios.post(url, data.sendData).then((response) => {
            return response.data;
        });
    },

    setChosenFormat({ commit }, data) {
        commit(types.SET_CHOSEN_FORMAT, data);
    },

    getEmbedCode(_, type) {
        let url = SETUP_FORM.SETTINGS + type + '-code';
        return axios.get(url).then((response) => {
            return response.data;
        });
    },
    updateProfileSetup(_, data) {
        let url = SETUP_FORM.PROFILE_SETUP;
        return axios.post(url, data).then((response) => {
            return response.data;
        });
    },
    uploadFile(_, data) {
        let url = SETUP_FORM.UPLOAD_FILE;
        const formData = new FormData();
        formData.append("file", data.file, data.name);
        return axios.post(url, formData).then((response) => {
            return response.data;
        });
    }
}
