import Vue from "vue";
import Vuex from "vuex";

import test from './modules/test';
import auth from './modules/auth';
import leads from './modules/leads';
import setupForm from './modules/setupForm';
import generateReport from './modules/generateReport';
import domainSettings from './modules/domainSettings';
import integrations from './modules/integrations';
import customizeReport from './modules/customizeReport';
import subscription from './modules/subscription';

Vue.use(Vuex);

export default new Vuex.Store({
  state: {},
  mutations: {},
  actions: {},
  modules: {
    test,
    auth,
    leads,
    setupForm,
    domainSettings,
    generateReport,
    integrations,
    customizeReport,
    subscription
  }
});
