import {CUSTOMIZE_REPORT} from "../../../../../shared/links";
import axios from "axios";


export default {
    getSettings(_, domain) {
        let url = CUSTOMIZE_REPORT.SETTINGS + '?domain_id=' + domain;
        return axios.get(url).then((response) => {
            return response.data;
        });
    },

    setSettings(_, data) {
        let url = CUSTOMIZE_REPORT.SETTINGS;
        return axios.post(url, data).then((response) => {
            return response.data;
        });
    },

    resetSettings() {
        let url = CUSTOMIZE_REPORT.SETTINGS_RESET;
        return axios.post(url).then((response) => {
            return response.data;
        });
    },

    getRecommendations(_, path) {
        let url = CUSTOMIZE_REPORT.GET_RECOMMENDATIONS + '?url=' + path;
        return axios.get(url).then((response) => {
            return response.data;
        });
    },

}
