import {TEST} from "../../../../../shared/links";
import axios from "axios";


const getTest = () => {
    return axios.get(TEST.GET_TEST_URL).then((response) => {
        return response.data;
    });
};

export default {
    getTest
}
