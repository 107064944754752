import * as types from './mutation-types';
import {AUTH} from "../../../../../shared/links";
import {LOGOUT} from "./mutation-types";

import helper from '../../../../../shared/services/helper.js';


import axios from "axios";

export default {
    refreshToken() {
        return new Promise((resolve, reject) => {
            const refresh_token = localStorage.getItem('refresh_token');
            axios.post(AUTH.REFRESH, {refresh_token: refresh_token})
                .then(response => {
                    if (response.status === 200) {
                        localStorage.setItem('access_token', response.data.access_token);
                        localStorage.setItem('refresh_token', response.data.refresh_token);

                        setTokenInCookie(response.data.access_token, response.data.expires_in)

                    }
                    resolve(response.data.access_token);
                })
                .catch(err => {
                    localStorage.removeItem('access_token');
                    localStorage.removeItem('refresh_token');
                    reject(err)
                })

        })
    },
    login({dispatch}, data){
        return new Promise((resolve, reject) => {
            axios.post(AUTH.LOGIN, data)
                .then(resp => {
                    const token = resp.data.access_token;
                    localStorage.setItem('access_token', resp.data.access_token);
                    localStorage.setItem('refresh_token', resp.data.refresh_token);
                    axios.defaults.headers.common['Authorization'] = 'Bearer ' + token;

                    setTokenInCookie(resp.data.access_token, resp.data.expires_in);

                    resolve(dispatch('me'))
                })
                .catch(err => {
                    localStorage.removeItem('access_token');
                    localStorage.removeItem('refresh_token');
                    reject(err)
                })
        })
    },
    updateUserInfo(_, data) {
        let url = AUTH.UPDATE_USER;
        return axios.post(url, data).then((response) => {
            return response.data;
        });
    },
    updateUserPassword(_, data) {
        let url = AUTH.UPDATE_PASSWORD;
        return axios.post(url, data).then((response) => {
            return response.data;
        });
    },

    me({commit}) {
        return new Promise((resolve, reject) => {
            axios.get(AUTH.ME)
                .then(resp => {
                    commit(types.SET_USER, resp.data);
                    resolve(resp.data)
                })
                .catch(err => {
                    reject(err)
                })
        })
    },

    signUp(_, data) {
        data.webstats = {
            fv_utm_source: helper.getCookie("FV_utm_source"),
            fv_utm_medium: helper.getCookie("FV_utm_medium"),
            fv_utm_campaign: helper.getCookie("FV_utm_campaign"),
            fv_utm_term: helper.getCookie("FV_utm_term"),
            fv_utm_content: helper.getCookie("FV_utm_content"),
            fv_utm_source_url: helper.getCookie("FV_Source_URL"),
            fv_utm_landingpage_url: helper.getCookie("FV_utm_landingpageurl"),
            fv_utm_visit_time: 0,
            fv_browser: helper.getCookie("FV_Browser"),
            ct_utm_source: helper.getCookie("LV_utm_source"),
            ct_utm_medium: helper.getCookie("LV_utm_medium"),
            ct_utm_campaign: helper.getCookie("LV_utm_campaign"),
            ct_utm_term: helper.getCookie("LV_utm_term"),
            ct_utm_content: helper.getCookie("LV_utm_content"),
            ct_utm_source_url: helper.getCookie("LV_utm_sourceurl"),
            ct_utm_landingpage_url: helper.getCookie("LV_utm_landingpageurl"),
            ct_utm_visit_time: 0,
            total_visit: 0,
            source_visit: {
                google:0,
                facebook:0,
                quora:0,
                linkedin:0,
                twitter:0,
                direct:0,
                other:0
            },
            medium_visit: {
                paid:0,
                organic:0,
                direct:0,
                other:0
            }
        };


        if (helper.getCookie("FV_utm_visitdatetime") !== '') {
            data.webstats.fv_utm_visit_time = parseInt(helper.getCookie("FV_utm_visitdatetime"));
        }
        if (helper.getCookie("LV_utm_visitdatetime") !== '') {
            data.webstats.ct_utm_visit_time = parseInt(helper.getCookie("LV_utm_visitdatetime"));
        }

        if (helper.getCookie("Total_Visits") !== '') {
            data.webstats.total_visit = parseInt(helper.getCookie("Total_Visits"));
        }


        if (helper.getCookie("Source_Google") !== '') {
            data.webstats.source_visit.google = parseInt(helper.getCookie("Source_Google"));
        }
        if (helper.getCookie("Source_Facebook") !== '') {
            data.webstats.source_visit.facebook = parseInt(helper.getCookie("Source_Facebook"));
        }
        if (helper.getCookie("Source_Quora") !== '') {
            data.webstats.source_visit.quora = parseInt(helper.getCookie("Source_Quora"));
        }
        if (helper.getCookie("Source_Linkedin") !== '') {
            data.webstats.source_visit.linkedin = parseInt(helper.getCookie("Source_Linkedin"));
        }
        if (helper.getCookie("Source_Twitter") !== '') {
            data.webstats.source_visit.twitter = parseInt(helper.getCookie("Source_Twitter"));
        }
        if (helper.getCookie("Source_Direct") !== '') {
            data.webstats.source_visit.direct = parseInt(helper.getCookie("Source_Direct"));
        }
        if (helper.getCookie("Source_Others") !== '') {
            data.webstats.source_visit.other = parseInt(helper.getCookie("Source_Others"));
        }


        if (helper.getCookie("Medium_paid") !== '') {
            data.webstats.medium_visit.paid = parseInt(helper.getCookie("Medium_paid"));
        }
        if (helper.getCookie("Medium_organic") !== '') {
            data.webstats.medium_visit.organic = parseInt(helper.getCookie("Medium_organic"));
        }
        if (helper.getCookie("Medium_direct") !== '') {
            data.webstats.medium_visit.direct = parseInt(helper.getCookie("Medium_direct"));
        }
        if (helper.getCookie("Medium_other") !== '') {
            data.webstats.medium_visit.other = parseInt(helper.getCookie("Medium_other"));
        }


        return new Promise((resolve, reject) => {
            axios.post(AUTH.SIGN_IN, data)
                .then(resp => {
                    const token = resp.data.access_token;
                    localStorage.setItem('access_token', resp.data.access_token);
                    localStorage.setItem('refresh_token', resp.data.refresh_token);

                    setTokenInCookie(resp.data.access_token, resp.data.expires_in);

                    axios.defaults.headers.common['Authorization'] = 'Bearer ' + token;
                    resolve(resp)
                })
                .catch(err => {
                    localStorage.removeItem('token');
                    reject(err)
                })
        })
    },
    resetPassword(_, data){
        return new Promise((resolve, reject) => {
            axios.post(AUTH.RESET_PASSWORD, data)
                .then(resp => {
                    const token = resp.data.access_token;
                    localStorage.setItem('access_token', resp.data.access_token);
                    localStorage.setItem('refresh_token', resp.data.refresh_token);
                    setTokenInCookie(resp.data.access_token, resp.data.expires_in);

                    axios.defaults.headers.common['Authorization'] = 'Bearer ' + token;
                    resolve(resp)
                })
                .catch(err => {
                    localStorage.removeItem('token');
                    reject(err)
                })
        })
    },
    forgotPassword(_, data) {
        return axios.post(AUTH.FORGOT_PASSWORD, data).then((response) => {
            return response.data;
        });
    },
    verifyToken(_, data) {
        let url = AUTH.RESET_PASSWORD + '?user_id=' + data.user_id + '&token=' + data.token;
        return axios.get(url).then((response) => {
            return response.data;
        });
    },

    logout({commit}) {
        return new Promise((resolve) => {
            return axios.get(AUTH.LOGOUT).then(() => {
                localStorage.removeItem('access_token');
                localStorage.removeItem('refresh_token');
                delete axios.defaults.headers.common['Authorization'];
                commit(LOGOUT);
                resolve()
            });


        })
    },
}
function setTokenInCookie(token, expire_secs) {
    let d = new Date();
    d.setTime(d.getTime() + expire_secs * 1000);
    let expires = "expires=" + d.toUTCString();

    document.cookie = "access_token=" + token + ";" + expires + ";path=/";

}
