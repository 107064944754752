import {DOMAIN} from "../../../../../shared/links";
import axios from "axios";


const getSettings = () => {
    return axios.get(DOMAIN.SETTINGS).then((response) => {
        return response.data;
    });
};
const saveSettings = (_, data) => {
    return axios.post(DOMAIN.SETTINGS, data).then((response) => {
        return response.data;
    });
};

const validateDomain = () => {
    return axios.get(DOMAIN.VALIDATE).then((response) => {
        return response.data;
    });
};
const deleteSettings = () => {
    return axios.delete(DOMAIN.SETTINGS).then((response) => {
        return response.data;
    });
};

export default {
    getSettings,
    saveSettings,
    validateDomain,
    deleteSettings
}
