import axios from "axios";
import store from "../../Apps/admin/store";
import router from "../../Apps/admin/router";



export default function axiosSetUp() {

    let refreshTokenPromise;

    // point to your API endpoint
    // axios.defaults.baseURL = `<${process.env.VUE_APP_BACKEND_URL}/api>`;
    // Add a request interceptor
    axios.interceptors.request.use(
        function(config) {
            // Do something before request is sent
            const token = localStorage.getItem('access_token');
            if (token) {
                config.headers.Authorization = `Bearer ${token}`;
            }
            return config;
        },
        function(error) {
            // Do something with request error
            return Promise.reject(error);
        }
    );

    // Add a response interceptor
    axios.interceptors.response.use(
        function(response) {
            // Any status code that lie within the range of 2xx cause this function to trigger
            // Do something with response data
            return response;
        },
        async function(error) {
            // Any status codes that falls outside the range of 2xx cause this function to trigger
            // Do something with response error
            const originalRequest = error.config;

            if (
                error.response.status === 401 &&
                originalRequest.url.includes("/login/refresh")
            ) {
                store.commit('auth/LOGOUT');
                await router.push("/login");
                refreshTokenPromise = null;

                return Promise.reject(error);
            } else if (error.response.status === 401 && !originalRequest._retry) {

                if (!refreshTokenPromise) {
                    originalRequest._retry = true;

                    refreshTokenPromise =  store.dispatch("auth/refreshToken") .then((token) => {
                        refreshTokenPromise = null;
                        return token

                    })
                }

                return refreshTokenPromise.then(token => {

                    originalRequest.headers.Authorization = `Bearer ${token}`;

                    return axios(originalRequest);
                })
            }
            return Promise.reject(error);
        }
    );
}
