import * as types from './mutation-types';

const SET_CHOSEN_FORMAT = (state, data) => {
    localStorage.setItem('chosenFormat', JSON.stringify(data));
    
    state.chosenFormat = JSON.parse(localStorage.getItem('chosenFormat'));
};

export default {
    [types.SET_CHOSEN_FORMAT]: SET_CHOSEN_FORMAT,
};
