import {LEADS} from "../../../../../shared/links";
import axios from "axios";
import * as types from "./mutation-types";


export default {
    getLeads(_, data) {
        let url = LEADS.LEADS_URL + '?page=' + data.page + '&limit=' + data.limit + '&sort_by=' + data.sort_by + '&sort_desc=' + data.sort_desc;
        return axios.get(url).then((response) => {
            return response.data;
        });
    },
    getBlockedLeads(_, data) {
        let url = LEADS.GET_BLOCKED_LEADS + '?page=' + data.page + '&limit=' + data.limit + '&sort_by=' + data.sort_by + '&sort_desc=' + data.sort_desc;
        return axios.get(url).then((response) => {
            return response.data;
        });
    },

    deleteLeads({commit}, data) {
        return axios.delete(LEADS.LEADS_URL, {data: {ids: data}}).then((response) => {
            commit(types.UNSELECT_ENTRY_IDS_FROM_TABLE_CHECKED_ITEMS, 'leads');
            return response.data;
        });
    },

    deleteBlockedLeads({commit}, data) {
        return axios.delete(LEADS.GET_BLOCKED_LEADS, {data: {ids: data}}).then((response) => {
            commit(types.UNSELECT_ENTRY_IDS_FROM_TABLE_CHECKED_ITEMS, 'block_leads');
            return response.data;
        });
    },

    uploadLeads(_, formData) {
        return axios.post(LEADS.UPLOAD_LEADS, formData)
            .then(response => {
                return response.data;
            })

    },

    downloadLeads() {
        return axios({
            url: LEADS.DOWNLOAD_LEADS, method: 'GET',

        }).then((response) => {
            var blob = new Blob([response.data]);
            var link = document.createElement('a');
            link.href = window.URL.createObjectURL(blob);
            link.download = 'Leads.csv';
            link.click();
        });
    },
    getSettings() {
        return axios.get(LEADS.GET_SETTINGS).then((response) => {
            return response.data;
        });
    },
    saveSettings(_, data) {
        return axios.post(LEADS.GET_SETTINGS, data).then((response) => {
            return response.data;
        });
    },

    addOrDeleteCheckedItem({commit}, data) {
        commit(types.CHANGE_TABLE_CHECKED_ITEMS, data);
    }

}


