import {INTEGRATIONS} from "../../../../../shared/links";
import axios from "axios";


const getData = () => {
    return axios.get(INTEGRATIONS.INTEGRATIONS_URL).then((response) => {
        return response.data;
    });
};
const updateData = (_, data) => {
    return axios.post(INTEGRATIONS.INTEGRATIONS_URL, data).then((response) => {
        return response.data;
    });
};
const createMailChimp = (_, code) => {
    const url = INTEGRATIONS.MAILCHIMP + '?code=' + code;
    return axios.get(url).then((response) => {
        return response.data;
    });
};
const createHubSpot = (_, data) => {
    const url = INTEGRATIONS.HUBSPOT + '?code=' + data.code + '&redirect_url=' + data.redirect_url;
    return axios.get(url).then((response) => {
        return response.data;
    });
};

const getMailChimpAudience = (_, id) => {
    const url = INTEGRATIONS.MAILCHIMP_AUDIENCE + '?id=' + id;
    return axios.get(url).then((response) => {
        return response.data;
    });
};
const updateConversionTracking = (_, data) => {
    return axios.post(INTEGRATIONS.CONVERSION_TRACKING, data).then((response) => {
        return response.data;
    });
};

export default {
    createMailChimp,
    createHubSpot,
    getMailChimpAudience,
    getData,
    updateData,
    updateConversionTracking
}
