import {SUBSCRIPTION_STATUS} from "../../../../../shared/constants";

const isAuthenticated = () => {
    return !!localStorage.getItem('access_token');
};

const getUser = state => {
    return state.user
};

const isUserSuspended = (state) => {
    return state.user.subscription_status === SUBSCRIPTION_STATUS.EXPIRED;
};


export default {
    isAuthenticated,
    getUser,
    isUserSuspended,
};
