import Vue from "vue";
import VueRouter from "vue-router";
import store from "../store";

Vue.use(VueRouter);

const routes = [
    {
        path: '/',
        redirect: {name: 'dashboard.setup-form'},
    },
    {
        path: '/dashboard',
        component: () =>
            import(/* webpackChunkName: "dashboard" */ "../../../shared/layouts/Dashboard"),
        children: [
            {
                path: '',
                redirect: {name: 'dashboard.setup-form.choose-format'},
            },
            {
                path: 'setup-form',
                name: 'dashboard.setup-form',
                redirect: {name: 'dashboard.setup-form.choose-format'},
                component: () =>
                    import(/* webpackChunkName: "setup-form" */ "../views/pages/SetupForm/SetupForm"),
                children: [
                    {
                        path: '',
                        redirect: {name: 'dashboard.setup-form.choose-format'},
                    },
                    {
                        path: 'choose-format',
                        name: 'dashboard.setup-form.choose-format',
                        component: () =>
                            import(/* webpackChunkName: "choose-format */ "../views/pages/SetupForm/ChooseFormat"),
                        meta: {
                            requiresAuth: true,
                            title: 'Setup Audit Form | Choose Format',
                        },
                    },
                    {
                        path: 'build-form',
                        name: 'dashboard.setup-form.build-form',
                        component: () =>
                            import(/* webpackChunkName: "build-form" */ "../views/pages/SetupForm/BuildForm"),
                        meta: {
                            requiresAuth: true,
                            title: 'Setup Audit Form | Build Form',
                        },
                    },
                    {
                        path: 'embed-code',
                        name: 'dashboard.setup-form.embed-code',
                        component: () =>
                            import(/* webpackChunkName: "embed-code" */ "../views/pages/SetupForm/EmbedCode"),
                        meta: {
                            requiresAuth: true,
                            title: 'Setup Audit Form | Embed Code',
                        },
                    },
                ]
            },

            {
                path: 'leads',
                name: 'dashboard.leads',
                redirect: {name: 'dashboard.leads.my-leads'},
                component: () =>
                    import(/* webpackChunkName: "leads" */ "../views/pages/Leads/Leads"),
                children: [
                    {
                        path: '',
                        redirect: {name: 'dashboard.leads.my-leads'},
                    },
                    {
                        path: 'my-leads',
                        name: 'dashboard.leads.my-leads',
                        component: () =>
                            import(/* webpackChunkName: "my-leads" */ "../views/pages/Leads/MyLeads"),
                        meta: {
                            requiresAuth: true,
                            title: 'Leads',
                        },
                    },
                    {
                        path: 'blocked-leads',
                        name: 'dashboard.leads.blocked-leads',
                        component: () =>
                            import(/* webpackChunkName: "blocked-leads" */ "../views/pages/Leads/BlockedLeads"),
                        meta: {
                            requiresAuth: true,
                            title: 'Blocked Leads',
                        },
                    },
                ]
            },
            {
                path: 'settings',
                name: 'dashboard.settings',
                redirect: {name: 'dashboard.settings.account'},
                component: () =>
                    import(/* webpackChunkName: "account" */ "../views/pages/Settings/Settings"),
                children: [
                    {
                        path: '',
                        redirect: {name: 'dashboard.settings.account'},
                        showIfSuspended: true,
                    },
                    {
                        path: 'account',
                        name: 'dashboard.settings.account',
                        component: () =>
                            import(/* webpackChunkName: "my-leads" */ "../views/pages/Settings/Account"),
                        meta: {
                            requiresAuth: true,
                            title: 'Settings | Account',
                            showIfSuspended: true,
                        },
                    },
                    {
                        path: 'billing',
                        name: 'dashboard.settings.billing',
                        component: () =>
                            import(/* webpackChunkName: "blocked-leads" */ "../views/pages/Settings/Billing"),
                        meta: {
                            requiresAuth: true,
                            title: 'Billing & My Plan',
                            showIfSuspended: true,
                        },
                    },
                ]
            },

            {
                path: 'generate-report',
                name: 'dashboard.generate-report',
                component: () =>
                    import(/* webpackChunkName: "generate-report" */ "../views/pages/GenerateReport"),
                meta: {
                    requiresAuth: true,
                    title: 'Generate report',
                },
            },

            {
                path: 'pick-plan',
                name: 'dashboard.pick-plan',
                component: () =>
                    import(/* webpackChunkName: "pick-plan" */ "../views/pages/PickPlan/PickPlan"),
                meta: {
                    requiresAuth: true,
                    title: 'Pick Plan',
                    showIfSuspended: true,
                },
            },
            {
                path: 'format-report',
                name: 'dashboard.format-report',
                component: () =>
                    import(/* webpackChunkName: "format-report" */ "../views/pages/FormatReport/FormatReport"),
                meta: {
                    requiresAuth: true,
                    title: 'Format report',
                },
            },
            {
                path: 'integrations',
                name: 'dashboard.integrations',
                component: () =>
                    import(/* webpackChunkName: "integrations" */ "../views/pages/Integrations"),
                meta: {
                    requiresAuth: true,
                    title: 'Integrations',
                },
            },
        ]


    },


    {
        path: '/test',
        name: 'app.test',
        component: () =>
            import(/* webpackChunkName: "test" */ "../views/pages/Test.vue"),
        meta: {
            requiresAuth: true,
            title: ' Title',
        }
    },
    {
        path: '/login',
        name: 'app.login',
        component: () =>
            import(/* webpackChunkName: "login" */ "../views/pages/Auth/Login"),
        meta: {
            title: 'Login',

        },
    },
    {
        path: '/logout',
        name: 'app.logout',
        component: () =>
            import(/* webpackChunkName: "logout" */ "../views/pages/Auth/Logout"),
        meta: {
            title: 'Logout',

        },
    },

    {
        path: '/sign-up',
        name: 'app.sign-up',
        component: () =>
            import(/* webpackChunkName: "SignUp" */ "../views/pages/Auth/SignUp"),
        meta: {
            title: 'Sign Up',

        }
    },
    {
        path: '/password/forgot',
        name: 'app.forgot-password',
        component: () =>
            import(/* webpackChunkName: "ForgotPassword" */ "../views/pages/Auth/ForgotPassword"),
        meta: {
            title: 'Forgot Password',

        }
    },
    {
        path: '/password/reset',
        name: 'app.reset-password',
        component: () =>
            import(/* webpackChunkName: "ResetPassword" */ "../views/pages/Auth/ResetPassword"),
        meta: {
            title: 'Reset Password',

        }
    },
    {
        path: '*',
        component: () =>
            import(/* webpackChunkName: "notFound" */ "../views/pages/PageNotFound"),

        meta: {
            title: 'Page not found'
        }
    }
];

const router = new VueRouter({
    mode: "history",
    base: process.env.BASE_URL,
    routes
});

router.beforeEach((to, from, next) => {
    //set titles for each page
    document.title = to.meta.title;

    if (to.matched.some(record => record.meta.requiresAuth)) {
        if (isAuthenticated()) {
            if (!to.matched.some(record => record.meta.showIfSuspended)) {
                if (store.getters['auth/isUserSuspended']) {
                    return next({name: 'dashboard.pick-plan'});
                } else {
                    next();
                }
            } else {
                next();
            }

        } else {
            return next({name: 'app.login'})
        }

    } else {
        if (to.matched.some(record => record.name === 'app.login') && isAuthenticated()) {
            return next('/');
        }
        return next();
    }

});

export default router;

function isAuthenticated() {
    return !!localStorage.getItem('access_token');
}
