import {SUBSCRIPTION} from "../../../../../shared/links";
import axios from "axios";

export default {
    getSubscription() {
        return axios.get(SUBSCRIPTION.URL).then((response) => {
            return response.data;
        });
    },
    updateSubscription(_, data) {
        return axios.post(SUBSCRIPTION.URL, data).then((response) => {
            return response.data;
        });
    },
    cancelSubscription(_, data) {
        return axios.delete(SUBSCRIPTION.URL, data).then((response) => {
            return response.data;
        });
    },
    getCardDetails() {
        return axios.get(SUBSCRIPTION.CARD_DETAILS).then((response) => {
            return response.data;
        });
    },
    getLastChargeSucceeded() {
        return axios.get(SUBSCRIPTION.LAST_CHARGE).then((response) => {
            return response.data;
        });
    },
    updateCardDetails(_,data) {
        return axios.post(SUBSCRIPTION.CARD_DETAILS, data).then((response) => {
            return response.data;
        });
    },
    getPlans() {
        return axios.get(SUBSCRIPTION.PLANS).then((response) => {
            return response.data;
        });
    },
    getInvoices(_, data) {
        let url = SUBSCRIPTION.INVOICES;
        url += data ? '?starting_after=' + data : '';

        return axios.get(url).then((response) => {
            return response.data;
        });
    },
    generate(_,data) {
        return axios.post(SUBSCRIPTION.GENERATE, data).then((response) => {
            return response.data;
        });
    },
    sync(_,data) {
        return axios.post(SUBSCRIPTION.SYNC, data).then((response) => {
            return response.data;
        });
    },
    getSetupIntent(){
        return axios.get(SUBSCRIPTION.SETUP_INTENT).then((response) => {
            return response.data;
        });
    },
    updatePaymentMethod(_, data){
        return axios.post(SUBSCRIPTION.PAYMENT_METHOD, data).then((response) => {
            return response.data;
        });
    }
}
