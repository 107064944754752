import actions from './actions';
import mutations from './mutations';
import getters from './getters';

const state = {
    tableCheckedItems: {
        leads: [],
        block_leads: [],
    }
};

export default {
    namespaced: true,
    state,
    actions,
    mutations,
    getters
};