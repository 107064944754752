import {REPORT} from "../../../../../shared/links";
import axios from "axios";

export default {
    createReport(_, data) {
        let url = REPORT.REPORT + '?website_url=' + data;

        return axios.post(url).then((response) => {
            return response.data;
        });
    }
}
