import Vue from "vue";
import {BootstrapVue, BootstrapVueIcons} from 'bootstrap-vue'
import App from "./App.vue";
import router from "./router";
import store from "./store";

import {notificationMixin} from "../../shared/mixins/notification-mixin";

import VueClipboards from 'vue-clipboards';

import { StripePlugin } from '@vue-stripe/vue-stripe';

const options = {
    pk: process.env.VUE_APP_STRIPE_PUBLISHABLE_KEY,
    stripeAccount: process.env.STRIPE_ACCOUNT,
    apiVersion: process.env.API_VERSION,
    locale: process.env.LOCALE,
};

Vue.use(StripePlugin, options);


import { Chrome } from 'vue-color'

import axiosSetup from "../../shared/services/axios";

axiosSetup();


Vue.config.productionTip = false;
Vue.use(BootstrapVue);
Vue.use(BootstrapVueIcons);
Vue.use(VueClipboards);
Vue.mixin(notificationMixin);
//Vee Validator
import {
    ValidationObserver,
    ValidationProvider,
    extend,
    localize
} from "vee-validate";
import en from "vee-validate/dist/locale/en.json";
import * as rules from "vee-validate/dist/rules";


import "bootstrap/dist/css/bootstrap.css";
import "bootstrap-vue/dist/bootstrap-vue.css";

// Install VeeValidate rules and localization
Object.keys(rules).forEach(rule => {
    extend(rule, rules[rule]);
});

localize("en", en);

// Install VeeValidate components globally
Vue.component("ValidationObserver", ValidationObserver);
Vue.component("ValidationProvider", ValidationProvider);
Vue.component("ChromePicker", Chrome);


new Vue({
    router,
    store,
    render: h => h(App)
}).$mount("#app");
